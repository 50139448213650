import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/helpers/http.service';
import { env } from 'src/env';

@Injectable({
  providedIn: 'root',
})
export class RatingService {
  constructor(private _http: HttpService, private http: HttpClient) {}

  /**
   * @model name
   */
  private model = 'ratings';

  /**
   * get rating list
   */
  list(params?) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}`,
      {
        params: params,
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param id
   * get rating by id
   */
  show(id: number) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param rating
   * @add rating
   */
  add(rating: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}`,
      rating,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param rating
   * @param id
   * @update rating
   */
  update(rating: any, id: number) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}`,
      rating,
      {
        headers: this._http.header,
      }
    );
  }

  statusUpdate(rating: any, id: number) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/status`,
      rating,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param id
   * @delete Package
   */
  delete(id: number) {
    return this.http.delete(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   * get perrticuler user flags
   */
  flags(id: number) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/flags`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param id
   * get user likes
   */
  likes(id: number) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/likes`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @returns doctor list
   */
  doctors() {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/doctors/summary`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   * get patient list
   */
  patients(query: any = '') {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/patients/summary`,
      {
        params: { q: query },
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @returns get institutes list
   */
  institutes() {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/institutes/summary`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param data
   * get kpi
   */
  kpi(data) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/ratings/kpis`,
      data,
      {
        headers: this._http.header,
      }
    );
  }
}
