import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) {}

  /**
   *
   * @param message
   * @param action
   * @return bottom snackbar popup of your message
   */
  openSnackBar(message: string, action = 'X',type='blue-snackbar') {
    this._snackBar.open(message, action, {
      duration: 3000,
      panelClass: [type],
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
